@import "src/vendor/css/variables.scss";

.eye-catcher {

	background-color: $color_white;
	color: $color_black;
	width: 100%;

	&__wrapper {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		position: relative;
		height: 100%;
		padding: 0.5rem 0;
	}

	&__firstline {
		flex-basis: auto;
		@include meister;
		font-size: 1.5rem;
		line-height: 1;
	}

	&__secondline {
		flex-basis: auto;
		@include meister;
		font-size: 1.5rem;
		line-height: 1;
		text-transform: uppercase;
	}
}
@media screen and (min-width: $breakpoint_tablet_from) {
	.eye-catcher {
		border-radius: 50%;
		width: 188px;
		height: 188px;
		display: flex;
		justify-content: center;
		align-items: center;


		&__wrapper {
			flex-flow: column nowrap;
			position: relative;
			transform: rotate(345deg);
		}

		&__firstline {
			font-size: 2.875rem;
			line-height: 0.8;
		}

		&__secondline {
			font-size: 1.2rem;
			line-height: 1;
		}
	}
}
