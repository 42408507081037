@import "src/vendor/css/variables.scss";

.dynamic-headline-copy {
	padding: $componentPaddingMobile 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}

	&__content-wrapper {
		max-width: 100%;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			max-width: $text_max_width;
		}
	}

	&__headline{
		margin-bottom: 4rem;
	}

	&__copy{
		p + p{
			margin-top: 1rem;
		}
	}
}
