@import "../../../vendor/css/variables";

.share-button {
	width: auto;
	max-width: 190px;
	border-radius: 0.5rem;
	max-height: 42px;
	padding: 0.75rem 1rem ;
	border: 1px solid	$color_beige;
	color: $color_beige;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding: 1rem 2rem;
		max-height: 50px;
	}

	&__icon{
		margin-left: 0.5rem;
		transform: translateY(-3px) rotate(-45deg);
	}

	&:hover{
		border: 1px solid $color_beige;
		background-color: $color_beige;
		color: $color_black;
	}
}
