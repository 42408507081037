@import "src/vendor/css/variables.scss";

.custom-text-headline {
	margin-bottom: 4rem;

	padding: $componentPaddingMobile 0 0 ;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0 0 ;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0 0 ;
	}

	&__content-wrapper {
		max-width: 100%;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			max-width: $text_max_width;
		}
	}
}
