@import "src/vendor/css/variables.scss";

.video-gallery {
	background-color: $color_black;
	color: $color_beige;

	padding: 0 0 $componentPaddingMobile;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: 0 0 $componentPadding;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding: 0 0 $componentPadding;
	}

	&__content-wrapper {
		max-width: 100%;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			max-width: $text_max_width;
			margin-top: 1rem;
		}
	}

	&__headline {
		margin-bottom: 4rem;
	}

	&__teasers {

		display: grid;
		grid-template-columns: 100%;
		grid-row-gap: 2.5rem;
		grid-template-rows: auto;
		grid-column-gap: 1.5rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			grid-template-columns: calc(50% - 0.75rem) calc(50% - 0.75rem);

			.video-gallery__teaser:nth-of-type(1) {
				grid-row: 1;
				grid-column: 1/3;
			}

		}
	}
}

.questions__section + .video-gallery {
	padding: $componentPaddingMobile 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}
}
