@mixin container {
	max-width: $viewport_width;
	margin: 0 1rem;
	display: flex;
	flex-flow: column nowrap;
	flex: 1;

	&--row {
		flex-flow: row nowrap;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		margin: 0 1.5rem;
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
		margin: 0 2rem;
	}

	@media only screen and (min-width: $breakpoint_widescreen_from) {
		margin: 0 auto;
	}
}

@mixin h1 {
	font-size: 3.187rem;
	line-height: 1.2;
	font-weight: bold;
	@media only screen and (min-width: $breakpoint_tablet_from) {
		font-size: 10rem;
	}
}

@mixin h1-dynamic {
	font-size: calc(44px + (160 - 50) * ((100vw - 375px) / (1280 - 375)));
	line-height: 1.2;
	font-weight: bold;
}

@mixin h2 {
	font-size: 2.75rem;
	line-height: 1.1;
	font-weight: bold;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		font-size: 4.5rem;
	}

}

@mixin h3 {
	font-size: 2rem;
	line-height: 1;
	font-weight: bold;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		font-size: 3rem;
	}
}

@mixin h4 {
	font-size: 1.5rem;
	line-height: 1;
	font-weight: bold;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		font-size: 1.75rem;
	}
}

@mixin arial {
	font-family: Arial, sans-serif;
}

@mixin meister {
	font-family: 'meister', Helvetica, "Helvetica Neue", Arial, sans-serif;
}

@mixin meister-regular {
	font-family: 'meister-regular', Helvetica, "Helvetica Neue", Arial, sans-serif;
}

@mixin handwriting {
	font-family: 'Covered By Your Grace', Helvetica, "Helvetica Neue", Arial, sans-serif;
}

@mixin copy-bold {
	font-weight: 600;
}

@mixin gradient-gold {
	background: $color_gold;
	background: $gradient-color_gold;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@mixin gradient-gold-dark {
	background: $color_gold-dark;
	background: $gradient-color_gold-dark;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
