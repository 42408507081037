@import "../../../vendor/css/variables.scss";

.sponsors {
	padding: $componentPaddingMobile 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}

	&__headline {
		text-transform: uppercase;
		margin-bottom: 4rem;

		&--colored {
			color: $color_orange;
		}
	}

	&__container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__hr {
		display: none;
	}

	&__image {
		//background: $color_orange;
		&--big-margin {
			margin-bottom: 4rem;
		}
	}

	&__item {
		flex-basis: calc(100% / 2 - 0.5rem);
		flex-shrink: 0;
		margin-bottom: 1.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media screen and (min-width: $breakpoint_tablet_from) {
		&__item {
			flex-basis: calc(100% / 4 - 0.75rem);
			flex-shrink: 0;
			margin-bottom: 0;
		}

		&__hr {
			display: block;
			margin: 1.5rem 0;
			border-width: 2px;
			color:$color_beige;
			border-style: solid;
		}
	}
}

