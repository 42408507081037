@import "../../../vendor/css/variables";

.collapsible {
	position: relative;
	display: block;
	color: beige;
	margin-bottom: 4rem;

	&__head-content {
		width: 100%;

		hr {
			height: 0;
			width: 70%;
			border: 3px solid $color_beige;
			margin: 1rem 0;
		}
	}

	&__toggle {
		pointer-events: none;
		margin-right: .5rem;

		.open {
			display: block;
		}

		.close {
			display: none;
		}
	}

	&__head {
		display: flex;
		position: relative;
		cursor: pointer;

		> div > label {
			padding-right: 1rem;
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {
			.collapsible__toggle {
				margin-right: 2rem;
				.icon {
					width: 4rem;
					height: 4rem;
				}
			}

			> div > label {
				padding-right: 3rem;
			}
		}
	}

	&__content {
		background-color: $color_black;
		position: relative;
		display: block;
		padding: 0 2rem;
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
	}

	&__inner-content {
		padding-left: .5rem;
		white-space: pre-wrap;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			padding-left: 4rem;
		}
	}

	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"]:checked {

		+ .collapsible__head {

			.collapsible__toggle {
				.open {
					display: none;
				}

				.close {
					display: block;
				}
			}
		}

		+ .collapsible__head + .collapsible__content {
			max-height: 8000px;
		}
	}
}
