@import "z-map.scss";
@import "mixins.scss";

// max with of the viewport on large screens
$viewport_width: 1280px;
$viewport_max_width: calc(#{$viewport_width} - 4rem);

// breakpoints
$breakpoint_widescreen_from: 1344px;
$breakpoint_widescreen_to: 1343px;
$breakpoint_desktop_from: 1024px;
$breakpoint_desktop_to: 1023px;
$breakpoint_tablet_from: 768px;
$breakpoint_tablet_to: 767px;
$breakpoint_mobile_from: 375px;

//Colors
$color_black: #000000;
$color_orange: #dd5a12;
$color_orange-dark: #ae470e;
$color_beige: #e9e3c7;
$color_beige-dark: #CFC9B0;
$color_gold: #fadf85;
$color_gold-dark: #f6c523;

$gradient-color_gold: linear-gradient(180deg, #fadf85 0%, #b7781e 100%);
$gradient-color_gold-dark: linear-gradient(180deg, #f6c523 0%, #6e4409 100%);

$color_grey: #BCBCBC;
$color_light_grey: #e0e0e0;
$color_white: #ffffff;

// sizes
$size_border_radius: 1rem;
$header_mobile_height: 6rem;
$header_height: 6.25rem;

$footer_height: 11.5rem;
$footer_mobile_height: 5.5rem;

$modal_header_height: $header_mobile_height;
$nav_header_height: 3rem;

$min_width: 320px;
$max_width: 1920px;

$text_max_width: 765px;

$componentPadding: 12.5rem;
$componentPaddingMobile: 6.25rem;

$marginBottomFull: 8rem;
$marginBottomHalf: 4rem;

// transitions
$transition_time: 0.5s;
$transition_hover: 0.35s ease;
$transition_in_time: 0.7s;
$transition_ease: cubic-bezier(0.4, 0, 0.2, 1);

$instagramPostHeaderHeight: 56px;
$instagramPostFooterHeight: 44px;
