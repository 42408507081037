@import "src/vendor/css/variables.scss";

.humanized-text {
	position: relative;
	display: flex;
	justify-content: flex-start;
	width: auto;

	isolation: isolate;

	&__overlayed {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__animation {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		&--circleArrow,
		&--stroke {

			> div {
				width: 100% !important;
				height: 100% !important;
			}

		}

		&--stroke {
			top: 50%;

			> div {
				transform: translate3d(0px, -4px, 0px);
			}
		}

		&--thinkStroke {
			> div {
				margin-top: -18%;
			}
		}

		&--circle {
			z-index: -1;
			left: -3%;
			width: 110%;
			top: -36%;
		}

		&--circleArrow {
			z-index: -1;
			left: -30%;
			width: 130%;
			top: -80%;
		}
	}

	&__markdown {
		> p {
			display: contents;
		}
	}
}
