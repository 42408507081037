@import "./src/vendor/css/variables.scss";

.questions {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: $breakpoint_desktop_from) {
		flex-direction: row;
	}

	&__container {
		position: relative;
	}

	&__cta {
		position: absolute;
		left: 0;
		bottom: 3rem;

		@media screen and (min-width: $breakpoint_desktop_from) {
			bottom: 25%;
		}
	}
}

.question {
	position: relative;
	flex: 1 0 0;
	height: 350px;
	@media screen and (min-width: $breakpoint_desktop_from) {
		height: 850px;
	}

	&__background {
		position: absolute;
		width: 100vw;
		height: 100%;
		top: 0;
		left: -1rem;
		background-color: $color_black;
		background-position: 5% 10%;
		background-size: 15%;

		&--when {
			background-image: url("../../../assets/static/icons/when.svg");
		}

		&--where {
			background-image: url("../../../assets/static/icons/where.svg");
		}

		&--who {
			background-image: url("../../../assets/static/icons/who.svg");
		}

		@media screen and (min-width: $breakpoint_tablet_from) {
			left: -1.5rem;
			background-size: 10%;
		}

		@media screen and (min-width: $breakpoint_desktop_from) {
			background-size: 5%;
			left: 0;

			.question:first-child & {
				right: 0;
				left: auto;
			}
		}
	}

	&__content {
		position: relative;
		margin: 3rem 0;

		.question:first-child & {
			margin-top: 6rem;
		}

		.question:last-child & {
			margin-bottom: calc(6rem + 42px);
		}

		@media screen and (min-width: $breakpoint_tablet_from) {
			margin: 5rem 0;

			.question:first-child & {
				margin-top: 8rem;
			}

			.question:last-child & {
				margin-bottom: calc(8rem + 42px);
			}
		}

		@media screen and (min-width: $breakpoint_desktop_from) {
			top: 30%;

			.question:first-child &, .question:last-child & {
				margin: auto;
			}

			.question:not(:first-child):not(:last-child) & {
				margin: 0 1.5rem;
			}
		}
	}

	&__headline {
		text-transform: uppercase;

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 3px;
			margin: 1rem auto;
			background-color: currentColor;
		}
	}

	&__text {
		padding: 1rem;
		background-color: black;
	}
}
