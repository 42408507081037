@import "src/vendor/css/variables.scss";

.socialmedia-wall {
	padding: $componentPaddingMobile 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}

	overflow: auto;
	background: $color_black;
	color: $color_beige;
	transition: color 0.5s, background-color 0.5s, border 3.5s;

	&--theme-light {
		background: $color_beige;
		color: $color_black;
		border-color: $color_black;

		.timeline__horizontal-line {
			border: 3px solid $color_black;
		}

		.timeline__vertical-line {
			border: 1px solid $color_black;
		}

	}
}
