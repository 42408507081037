@import "../../../vendor/css/variables";

.img {
	position: relative;
	overflow: hidden;
	height: auto;
	width: 100%;
	transition: opacity .3s linear;
	animation: fadeIn .2s ease;

	&--is-loading {
		background-color: $color_light_grey;
		animation: pulsate 1.5s infinite none;

		img {
			opacity: 0;
		}
	}

	&--is-hidden {
		display: none;
	}

	&[style*="padding-top"] {
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	img {
		display: block;
		height: auto;
		width: 100%;
		transition: opacity .3s ease-in
	}
}
