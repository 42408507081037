@import "../../../vendor/css/common-styles.scss";
@import "../../../vendor/css/variables.scss";

.challenges {
	padding: $componentPaddingMobile 0;
	margin-bottom: -4rem;

	button {
		width: 220px;
		margin-bottom: 2.5rem;
	}

	&__head {
		max-width: 345px;
	}

	&__headline {
		text-transform: uppercase;
		margin-bottom: 2rem;

		&--orange {
			color: $color_orange;
		}
	}

	&__info {
		margin-bottom: 2rem;

		/*specific positioning of animation */
		.humanized-text {
			display: inline;

			&__overlayed {
				white-space: nowrap;
				display: table-cell;
			}

			&__animation{
				top: -53%;
				left: -20px;
				width: 134%;
			}
		}
	}

	&__column {
		&--left {
			flex: 0 0 80px;
			margin-right: 0.5rem;


			@media screen and (max-width: $breakpoint_mobile_from - 1){
				flex: 0 0 60px;
			}
		}

		&--right {
			max-width: $text_max_width;
		}
	}

	&__icon {
		margin: 0 auto;
	}

	&__hr {
		border: 0;
		height: 3px;
		width: 13.73rem;
		background-color: currentColor;
		margin: 1rem 0;
	}

	&__challenge {
		display: flex;
		flex-direction: row;
		margin-bottom: 4rem;

		&__label {
			display: table;
			margin-bottom: 1.5rem;
			text-transform: uppercase;
		}

		&__info {
			hyphens: auto;
		}
	}

	&__button {
		color: orangered;
	}
}

@media screen and (min-width: $breakpoint_tablet_from) {
	.challenges {
		&__column {
			&--left {
				margin-right: 1.5rem;
			}
		}

		&__challenge {
			&__info {
				margin-top: 1.5rem;
			}
		}

		&__info {

			/*specific positioning of animation */
			.humanized-text {
				&__animation {
					top: -55%;
					left: -20px;
					width: 132%;
				}
			}
		}
	}
}

@media screen and (min-width: $breakpoint_tablet_from) {
	.challenges {
		button {
			width: 19.25rem;
			margin-bottom: 4rem;
		}

		&__head {
			max-width: $text_max_width;
		}

		&__info {
			margin-bottom: 4rem;
			 /*specific positioning of animation */
			 .humanized-text {
				 &__animation {
					 top: -62%;
					 left: -31px;
					 width: 135%;
				 }
			 }
		 }
	}
}

@media screen and (min-width: $breakpoint_desktop_from) {
	.challenges {
		padding: $componentPadding 0;

		&__info {
			/*specific positioning of animation */
			.humanized-text {
				&__animation {
					top: -62%;
					left: -31px;
					width: 135%;
				}
			}
		}
	}
}
