@import "src/vendor/css/variables.scss";

.countdown {
	@include meister;
	color: $color_orange;
	margin-bottom: $marginBottomHalf;

	&__column-wrapper {
		display: flex;
		flex-flow: row nowrap;
	}

	&__column {
		display: flex;
		flex-flow: column nowrap;
	}

	&__value {
		font-size: 4rem;
		line-height: 1.2;
		text-align: center;

		&--beige {
			margin-top: 2px;
			color: $color_beige;
		}
	}

	&__label {
		font-size: 1rem;
		margin-top: -0.25rem;
		color: $color_beige;
		text-align: center;
	}

}
