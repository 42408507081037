@import "src/vendor/css/variables.scss";



.video-voting-winner,
.video-voting {
	background-color: $color_black;
	color: $color_beige;
	margin-bottom: 6.25rem;

	padding: $componentPaddingMobile 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		margin-bottom: 12.5rem;
	}

	&__content-wrapper {
		max-width: 100%;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			max-width: $text_max_width;
		}
	}

	&__hook {
		height: 100%;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		margin: auto 0.5rem auto 0;
	}

	&__headline {
		display: block;
		margin-bottom: 2rem;

		.humanized-text__overlayed {
			display: inline-flex;
		}

		.humanized-text__animation {
			top: 60%;
			left: -5%;
			transform: rotate(3deg);
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-bottom: 4rem;
		}
	}

	&__copy {
		margin-bottom: 4rem;
	}

	&__teasers {
		display: grid;
		grid-template-columns: 100%;
		grid-row-gap: 4rem;
		grid-template-rows: auto;
		grid-column-gap: 1.5rem;

	}

	&-teaser {
		display: grid;
		grid-template-rows:auto;
		grid-template-columns: auto;

		color: $color_beige;
		width: 100%;

		&__video-wrapper {
			grid-row: 4/5;
			grid-column: 1/1;
			width: 100%;
			margin-bottom: 1.5rem;
		}

		&__votes {
			grid-row: 1/2;
			grid-column: 1/1;
		}

		&__headline {
			grid-row: 2/3;
			grid-column: 1/1;

			@include meister;
			color: $color_orange;
			text-transform: uppercase;
			line-height: 1;
			margin-bottom: 0.5rem;

			font-size: 2.5rem;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				font-size: 3rem;
			}
		}

		&__subline {
			@include meister;
			font-size: 2rem;
			color: $color_gold;
			text-transform: uppercase;
			line-height: 1;
			margin-bottom: 0.5rem;

		}

		&__copy {
			grid-row: 3/4;
			grid-column: 1/1;
			margin-bottom: 1.5rem;

		}

		&__button {
			grid-row: 5/6;
			grid-column: 1/1;
			align-items: flex-end;

		}

		@media only screen and (min-width: $breakpoint_tablet_from) {
			grid-template-columns: 50% 50%;
			grid-template-rows: auto auto auto max-content;

			&__video-wrapper {
				grid-row: 1/6;
				grid-column: 1/2;
				place-self: start start;
			}

			&__votes {
				grid-row: 1/2;
				grid-column: 2/3;
				margin-left: 1.5rem;
			}

			&__headline {
				grid-row: 2/3;
				grid-column: 2/3;
				margin-left: 1.5rem;
				line-height: 1.2;
				margin-bottom: 0.25rem;

			}

			&__subline {
				line-height: 1.2;
				margin: 0.25rem 0;

			}

			&__copy {
				grid-row: 3/4;
				grid-column: 2/3;
				margin-left: 1.5rem;
				margin-bottom: 1rem;
			}

			&__button {
				grid-row: 4/5;
				grid-column: 2/3;
				margin-left: 1.5rem;
			}
		}
	}
}


.video-voting-winner{
	padding: 2rem 0;
}
