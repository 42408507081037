@import "src/vendor/css/variables.scss";

.voting-bar {
	$voting-bar-height: 16px;
	$voting-border-width: 3px;
	position: relative;


	&__loader {
		> * {
			counter-reset: count;
			animation: loader-loop 2s infinite;

			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					animation-delay: 100ms * $i;
				}
			}
		}
	}

	&__label {
		display: flex;
		align-items: center;
		font-size: 1.75rem;
		margin-bottom: 0.25rem;

		&-icon {
			margin-right: 0.5rem;
		}
	}

	&__border {
		position: absolute;
		top: -5px;
		right: -2px;
		width: 100%;
		height: $voting-bar-height;

		&-top, &-bottom {
			content: '';
			position: absolute;
			top: 0;
			left: 8px;
			width: calc(100% - #{$voting-bar-height});
			height: $voting-border-width;
			border-radius: 2px;
			background-color: currentColor;
		}

		&-bottom {
			top: auto;
			bottom: 0;
		}

		&-end {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: $voting-bar-height;
			border-radius: 0 $voting-bar-height/2 $voting-bar-height/2 0;
			border: $voting-border-width solid currentColor;
			border-left: 0;
		}
	}

	&__progress {
		background-color: $color-orange;
		height: 14px;
		border-radius: 7px;
		width: 0;
		transition: width .25s ease-out;
	}
}

@keyframes loader-loop {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
