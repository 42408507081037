@import "src/vendor/css/variables.scss";

.main-page {
	background-color: $color_black;
	color: $color_beige;
	overflow-x: hidden;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding-top: $header_height;
	}
}

.main-page__insta-wrapper {
	display: flex;
	width: 100%;
	height: 600px;

	.instagram-media {
		position: relative;
		width: 300px;
		height: 600px;

		border: 1px solid red;
	}
}
