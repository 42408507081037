@import "../../vendor/css/variables";

.faq-page {
	color: $color_beige;
	padding: $componentPaddingMobile 0;

	@media only screen and (min-width: $breakpoint_tablet_from) {

		padding: $componentPadding 0;
	}

	&__content-wrapper {
		max-width: 100%;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			max-width: $text_max_width;
		}
	}

	&__headline {
		margin-bottom: 4rem;
	}

	&__section-head {
		margin-bottom: 4rem;
	}

	&__section:not(:last-child) {
		margin-bottom: 8rem;
	}
}
