@import "src/vendor/css/variables.scss";

.footer {
	background-color: $color_black;
	color: $color_beige;
	position: relative;

	padding: 1rem 1rem;
	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding: 4rem 0;
	}

	&__navigation {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 1rem 0 2rem;

		font-size: 1rem;

		&-item {
			display: flex;
		}
		@media only screen and (min-width: $breakpoint_tablet_from) {
			padding-bottom: 4rem;
		}
	}

	&__item {
		padding: 0 0.5rem;
	}

	&__delimiter {
		width: 2px;
		height: 20px;
		margin: 2px 1rem 0;
		background-color: $color_beige;

	}
	&__subline {
		margin-bottom: 1rem;
		text-align: center;
		font-size: 0.75rem;
		line-height: 1.125rem;
		a {
			font-weight: 700;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
