@import "fonts";
@import "variables";

*, *:before, *:after {
	box-sizing: inherit;
}

_:-ms-input-placeholder, :root a div {
	background-image: url("iehack:///");
}

#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	flex: 1 1 auto;
}

html {
	font-size: 16px;
}

body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden !important;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $color_black;

	@include arial;

	font-weight: normal;
	font-size: 1.125rem;
	line-height: 1.5;
	color: $color_black;
	text-align: left;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		font-size: 1.75rem;
	}
}

img {
	max-width: 100%;
}

.round-border {
	border-radius: $size_border_radius;
}

.underline-border {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -2px;
		width: 100%;
		height: 3px;
		background: currentColor;
	}
}

.container {
	@include container;
}

.lock-scroll {
	overflow: hidden;
}

h1, h2, h3, h4, h5, h6,
ul, li, p {
	margin: 0;
	padding: 0;
}

hr {
	margin-left: auto;
	margin-right: auto;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4 {
	@include meister;

}

h1, .h1 {
	@include h1;

	&.dynamic-fontsize {
		@include h1-dynamic;
	}

}

h2, .h2 {
	@include h2;
}

h3, .h3 {
	@include h3;
}

h4, .h4 {
	@include h4;
}

h5, h6 {
	@include h4;
}

.handwriting {
	@include handwriting;

	/*
	defined in styleguide, but not useful
	font-size: 1.375rem;
	@media only screen and (min-width: $breakpoint_tablet_from) {
		font-size: 2.06rem;
	}
	*/
}

.gradient-gold {
	@include gradient-gold;

	&--dark {
		@include gradient-gold-dark;
	}
}

.highlight {
	color: $color_orange;
}

b, strong {
	@include copy-bold;
}

a {
	color: inherit;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
		font-weight: 700;
	}
}

.hyphenate {
	word-wrap: break-word;
	overflow-wrap: break-word;
	hyphens: auto;
}

input {
	// remove autofill background on firefox
	filter: grayscale(21%);

	// remove autofill background on webkit browsers
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
	}
}

input, select, textarea, button, a, label {
	-webkit-tap-highlight-color: transparent;
	outline: none;

	&:focus {
		border-color: $color_gold;
	}
}

button{
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
}
/*
.moritz-kopfschmerzen {
	background-color: deeppink;
	color: sandybrown;
	font-size: 2rem;

	animation: blinkingText 1.2s infinite;
}
*/
@keyframes blinkingText {
	0% {
		background-color: deeppink;
	}
	49% {
		background-color: deeppink;
	}
	60% {
		background-color: transparent;
	}
	99% {
		background-color: transparent;
	}
	100% {
		background-color: deeppink;
	}
}
