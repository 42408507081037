@import "../../vendor/css/variables.scss";

.agegate {
	color: $color_beige;
	width: 100%;
	height: 100%;
	margin-top: $marginBottomFull;
	@media only screen and (min-width: $breakpoint_tablet_from) {
		margin-top: 0;
		margin-bottom: $marginBottomFull;
	}

	&__container {
		@include container;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			padding-top: $marginBottomFull;
		}
	}

	&__headline {
		margin-bottom: 3rem;

		.humanized-text {
			&__animation {
				margin-top: 2rem;
				transform: rotate(3deg) translate3d(0px, -27px, 0px);
			}
		}

	}

	&__info-text {
		margin-bottom: 2rem;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-bottom: 8rem;
		}
		max-width: $text_max_width;
	}

	&__label-text {
		margin-bottom: 2rem;
		font-weight: 600;
	}

	&__date {
		display: flex;
		flex-flow: column nowrap;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			flex-flow: row nowrap;
		}
	}

	&__row {
		display: inline-flex;
	}

	&__input {
		@include meister;
		color: $color_beige;
		background-color: $color_black;
		display: flex;
		flex-flow: column nowrap;
		width: 100%;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			width: 10rem;
		}

		&--year {
			width: 100%;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				width: 20rem;
			}
		}

		input {
			height: 6rem;
			font-size: 3rem;
			padding: 1.5rem;
			border: 2px solid $color_beige;
			text-align: center;

			&[data-error="true"] {
				border-color: $color_orange;
			}
		}

		label {
			font-size: 0.75rem;
			margin-top: 3px;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				font-size: 1.4rem;
			}
		}
	}

	&__select {
		max-width: 672px;
		margin-top: 4rem;
	}

	&__modal-text {
		text-transform: uppercase;
		margin-bottom: 1rem;
	}

	&__modal-btn {
		margin-top: 2rem;
	}

	&__row + &__row {
		margin-top: 2rem;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-top: 0;
			margin-left: 1rem;
		}
	}

	&__input + &__input {
		margin-left: 1rem;
	}
}
