@import "src/vendor/css/common-styles.scss";

.header {
	background-color: $color_black;
	z-index: $zindex_header;
	width: 100%;
	position: fixed;
	display: flex;
	justify-content: center;

	height: $header_mobile_height;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		height: $header_height;
	}

	&__wrapper {
		display: flex;
		@include container;
		flex-flow: row nowrap;

		justify-content: space-between;
		align-items: center;
		padding: 0;
	}

	&__logo-link {
		max-width: 180px;
		margin-right: 0.75rem;
		margin-left: 0.25rem;
	}

	&__logo {
		width: 100%;
		max-height: 96px;
		height: auto;
	}

	&__button {
		&--mobile {
			@media only screen and (min-width: $breakpoint_desktop_from) {
				display: none;
			}
		}

		&--desktop {
			display:none;
			@media only screen and (min-width: $breakpoint_desktop_from) {
				display: block;
			}
		}
	}

	&__navigation {
		position: static;
		display: flex;
		justify-content: space-between;

		align-items: center;
		color: $color_white;
		padding: 0.5rem 0;


		font-size: 0.75rem;
		flex-flow: row wrap;

		&-wrapper {
			display: flex;
			flex-flow: row-reverse nowrap;
			justify-content: center;
			align-items: center;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				flex-flow: row nowrap;
			}
			.header__navigation-item {
				font-weight: 600;
			}
		}

		&-item + &-item {
			@media only screen and (min-width: $breakpoint_tablet_from) {
				margin-left: 1.25rem;
			}

			@media only screen and (min-width: $breakpoint_desktop_from) {
				margin-left: 2.5rem;
			}
		}

		&-item {
			position: relative;
			text-align: center;
			font-size: 1.25rem;
			padding: 0.75rem 0;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				font-size: 1rem;
				font-weight: normal;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 8px;
					width: 0;
					height: 3px;
					background: currentColor;
					transition: width .25s ease-out;
				}

				&:hover {
					text-decoration: none;
				}

				@media (hover: hover){
					&:hover:after {
						width: 100%;
						//transition: width .3s;
					}
				}
			}

			&--active {
				&:after {
					width: 100%;
				}
			}
		}

		&-button {
			@media only screen and (min-width: $breakpoint_tablet_from) {
				margin-left: 2.5rem;
			}
		}

		&--is-open {
			.header__navigation-burger-menu {
				height: auto;
				max-height: 100vh;
			}
		}

		&-burger-menu {
			display: flex;
			flex-flow: column nowrap;
			transition: max-height 0.5s;
			max-height: 0;
			height: 0;
			position: absolute;
			list-style: none;
			padding: 0;
			margin: 0;
			width: 100%;
			overflow: hidden;
			background-color: $color_beige;
			color: $color_black;
			top: $modal_header_height;
			left: 0;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				position: relative;
				background-color: transparent;
				color: $color_beige;
				flex-flow: row nowrap;
				top: 0;
				max-height: 100%;
				height: auto;
			}
		}

		&-burger {
			position: relative;
			width: 2rem;
			height: 2rem;
			flex: 0 0 auto;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				display: none;
			}
		}

		&-burger-line {

			fill: none;
			stroke: $color_beige;
			stroke-width: 6;
			transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
			stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

			&--one {
				stroke-dasharray: 60 207;
				stroke-width: 6;
			}

			&--two {
				stroke-dasharray: 60 60;
				stroke-width: 6;
			}

			&--three {
				stroke-dasharray: 60 207;
				stroke-width: 6;
			}
		}

		&--is-open {

			.header__navigation-burger-line--one {
				stroke-dasharray: 90 207;
				stroke-dashoffset: -134;
				stroke-width: 6;
			}

			.header__navigation-burger-line--two {
				stroke-dasharray: 1 60;
				stroke-dashoffset: -30;
				stroke-width: 6;
			}

			.header__navigation-burger-line--three {
				stroke-dasharray: 90 207;
				stroke-dashoffset: -134;
				stroke-width: 6;
			}
		}
	}
}
