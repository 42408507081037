@import "../../../vendor/css/variables";

.cookiefirst-cookie-declaration button,
.button {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: color $transition_hover, background-color $transition_hover, background $transition_hover, opacity $transition_hover;
	outline: none;
	border: none;
	position: relative;

	color: $color_white;
	font: inherit;
	@include arial;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	text-transform: none;
	letter-spacing: 0.5px;
	font-size: 1.125rem;
	white-space: nowrap;
	padding: 0.75rem 1rem;
	text-decoration: none;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding: 1rem 1.5rem;
	}

	&__label {
		position: relative;
	}

	&__icon {
		position: relative;
	}

	&__label + &__icon {
		margin-left: 0.5rem;
	}

	&--primary {
		background-color: $color_orange;

		&:hover,
		&:focus,
		&:active {
			background-color: $color_orange-dark;
			color: $color_white;

		}
	}

	&--secondary {
		background: $color_beige;
		border: 1px solid $color_beige;
		color: $color_black;

		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: opacity $transition_hover;
			border-radius: 0.5rem;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: $color_beige-dark;
			border: 1px solid $color_beige-dark;

			&:before {
				opacity: 1;
			}
		}
	}

	&--tertiary {
		background-color: $color_beige;
		color: $color_black;

		.icon {
			color: $color_black;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: $color_white;
			color: $color_orange;

			.icon {
				color: $color_orange;
			}

		}
	}

	&--small {
		font-size: 0.75rem;
		padding: 0.5rem 1rem;

		.button__icon {
			height: 8px;
			margin-left: 0.2rem;
		}
	}

	&--round-border {
		border-radius: 0.5rem;
	}

	&--full-width {
		width: 100%;
	}

	// Fit content size
	&--fit {
		width: auto;
		width: fit-content; // no IE11 support
	}

	&--fixed {
		width: 308px;
	}

	&__anchor--fit {
		text-decoration: none !important;
	}

	&__anchor--fullwidth {
		width: 100%;
		display: block;

		button {
			display: inline-flex;
			width: 100%;
		}
	}

	&[disabled] {
		background-color: $color_light_grey;
		color: $color_grey;
		pointer-events: none;

		&:hover,
		&:focus,
		&:active {
			background-color: $color_light_grey;
			color: $color_grey;
		}
	}

	&--ghost[disabled] {
		border: 2px solid $color_orange-dark;
		background-color: $color_light_grey;
		color: $color_orange-dark;
		pointer-events: none;

		&:hover,
		&:focus,
		&:active {
			background-color: $color_light_grey;
			color: $color_grey;
		}
	}
}
