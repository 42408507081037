@import "src/vendor/css/variables.scss";

.custom-text-headlinecopy {
	padding: $componentPaddingMobile 0 2rem 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0 4rem 0;
	}

	&__content-wrapper {
		max-width: 100%;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			max-width: $text_max_width;
		}

		> .h2 {
			display: table-cell;
			text-transform: uppercase;
		}
	}

	&__headline {
		margin-bottom: 2rem;
		line-height: 1.2;
		display: contents;
		text-transform: uppercase;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-bottom: 4rem;
		}

		.humanized-text__overlayed {
			display: inline-flex;
			color: $color_beige
		}

		.humanized-text__animation {
			top: -16%;
			left: -9%;
			width: 115%;
			transform: rotate(4deg);

			@media only screen and (min-width: $breakpoint_tablet_from) {
				top: -20%;
				left: -10%;
				width: 115%;
				transform: rotate(3deg);
			}
		}
	}

	&__copy {
		margin-top: 2rem;
		white-space: pre-wrap;
	}

}
