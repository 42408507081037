@import "../../../vendor/css/variables.scss";

.select {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	@include meister();

	select {
		@include meister();
		appearance: none;
		text-align: left;
		background-color: $color_black;
		color: $color_beige;
		border: 2px solid $color_beige;
		border-radius: 2px;
		cursor: pointer;
		transition: border-color $transition_hover;
		text-transform: uppercase;

		height: 6rem;
		font-size: 2rem;
		padding: 1.5rem;

		&::-ms-expand {
			display: none; /* hide the default arrow in ie10 and ie11 */
		}

		&:focus {
			outline: none;
		}

		&:disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		&:disabled + .select__icon-wrapper {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	&__label {
		z-index: 1;
		color: $color_beige;
		line-height: 1;

		font-size: 0.75rem;
		margin-top: 8px;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			font-size: 1.4rem;
		}

	}

	&.readonly {
		label {
			&:after {
				background-color: #fcfcfc;
			}
		}
	}

	&.field-error {
		label {
			color: $color_orange;
		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		right: .715rem;
		font-size: 1rem;
		transform: translateY(-50%);
		line-height: normal;
		transition: color $transition_hover;
		pointer-events: none;
	}

	&--theme-dark {
		.select__label {
			color: $color_light_grey;

			&:after {
				background-color: $color_black;
			}
		}

		select {
			background-color: $color_black;
			border-color: $color_beige;
			color: $color_white;
		}

		.select__icon-wrapper {
			.select__icon {
				&:before {
					color: $color_light_grey;
					transition: color $transition_hover;
				}
			}
		}

		&:hover {
			select {
				border-color: $color_gold-dark;
			}

			.select__icon-wrapper {
				color: $color_black;

				.select__icon {
					&:before {
						color: $color_gold-dark;
					}
				}
			}
		}
	}

	&--small {
		select {
			padding: 0.625rem 2rem 0.625rem 1rem;
		}
	}

	&--full-width {
		width: 100%;

		select {
			width: 100%;
		}
	}

}
