@font-face {
	font-family: 'meister';
	src: url(/assets/static/fonts/meister.eot) format('eot');
	src: url(/assets/static/fonts/meister.woff) format('woff'),
	url(/assets/static/fonts/meister.ttf) format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

@font-face {
	font-family: 'meister-regular';
	src: url(/assets/static/fonts/meister-regular.eot) format('eot');
	src: url(/assets/static/fonts/meister-regular.woff) format('woff'),
	url(/assets/static/fonts/meister-regular.ttf) format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* covered-by-your-grace-regular - latin */
@font-face {
	font-family: 'Covered By Your Grace';
	font-style: normal;
	font-weight: 400;
	src: url('/assets/static/fonts/covered-by-your-grace-v10-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('/assets/static/fonts/covered-by-your-grace-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('/assets/static/fonts/covered-by-your-grace-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('/assets/static/fonts/covered-by-your-grace-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('/assets/static/fonts/covered-by-your-grace-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('/assets/static/fonts/covered-by-your-grace-v10-latin-regular.svg#CoveredByYourGrace') format('svg'); /* Legacy iOS */
}
