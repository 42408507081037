@import "../../../vendor/css/variables";

.link {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	transition: color $transition_hover;

	position: relative;
	color: inherit;
	text-decoration: none;

	&:hover {
		color: currentColor;
		opacity: 0.6;

	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&--bold{
		font-weight: 600;
	}

	&__icon + &__label {
		margin-left: 0.5rem;
	}

	&--small{
		font-size: 0.75rem;
		.icon{
			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}
}

