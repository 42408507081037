@import "src/vendor/css/variables.scss";
@import "src/vendor/css/mixins.scss";

.imprint-page {
  &__subline{
    @include arial();
    font-weight: 600;
    margin: 2rem 0 1rem;
  }
  &__item{
    margin: 0 0 1.5rem;
  }
}
