.video-teaser {
	overflow: hidden;
	width: 100%;

	padding-bottom: 56.25%;
	position: relative;

	border-radius: 1rem;

	&:hover {
		.icon.video-teaser__play {
			opacity: 1;
		}
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		position: absolute;
		width: 100%;
		transform: translateY(-12.5%);

	}

	img {
		width: 100%;
		position: relative;
	}

	.icon.video-teaser__play {
		height: 20%;
		width: 20%;
		position: absolute;
		opacity: 0.7;
		transition: opacity 0.5s ease;
	}
}

.youtube-blocker {
	padding: 2rem;

	&__headline {
		text-transform: uppercase;
		margin-bottom: 2rem;
	}

	&__copy {
		margin-bottom: 2rem;
	}
}
