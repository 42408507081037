@import "src/vendor/css/variables.scss";

.text-page {
	color: $color_beige;
	font-size: 1rem;

	padding: $componentPaddingMobile 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}


	&__content {
		padding-top: 1rem;
		max-width: $text_max_width;
		hyphens: auto;
	}

	h1 {
		@include h2;
		@include meister;
		text-transform: uppercase;
		margin-bottom: 2rem;
	}

	&__numbers {
		font-size: 2rem;
		text-transform: uppercase;
		color: $color_orange;
		margin-bottom: 1.5rem;
		margin-top: $marginBottomFull;
	}

	h3,
	&__paragraph-headline {
		@include copy-bold;
		margin-bottom: 1rem;
	}

	p {
		margin-bottom: 2rem;
	}

	ul {
		padding-left: 1rem;
		list-style-position: inside;
		margin-bottom: 2rem;
	}

	.cookiefirst-cookie-declaration {
		> h2 {
			@include meister;
			@include copy-bold;
			font-size: 1.75rem;
			margin-bottom: 1rem;
			text-transform: uppercase;
		}

		> h3 {
			@include meister;
			@include copy-bold;
			font-size: 1.5rem;
			margin-bottom: 1rem;
			margin-top: 2rem;
			text-transform: uppercase;
		}

		> p {
			margin-bottom: 2rem;
		}

		> p + p {
			margin-bottom: 1rem;
		}

		h2 + p, h3 + p {
			margin-top: 2rem;
		}

		table {
			margin-top: 3rem;
			color: $color_beige;
			max-width: 100%;
		}

		button[type="button"] {
			display: inline-flex;
			background-color: $color_orange;
			border-radius: 0.5rem;

			&:hover,
			&:focus,
			&:active {
				background-color: $color_orange-dark;
				color: $color_white;

			}
		}

		button[type="button"] + button[type="button"] {
			margin-left: 1rem;
		}
	}

}

