@import "../../../vendor/css/variables";
@import "../../../vendor/css/z-map";

$animationSpeed: 0.3s;

$modal: '.modal';

#{$modal} {
	z-index: $zindex_modal;
	position: fixed;

	display: flex;
	align-items: center;
	width: auto;
	height: auto;

	&--small,
	&--big,
	&--medium,
	&--fullsize,
	&--fullscreen {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	&--auto {
		#{$modal} {
			&__close {
				padding: 0;
			}
		}
	}

	&--theme-light {
		#{$modal} {
			&__layer {
				background-color: $color_beige;
			}

			&__close {
				color: inherit;
				background-color: transparent;
			}
		}
	}

	&--theme-dark {
		#{$modal} {
			&__layer {
				background-color: $color_black;
				color: $color_beige;
			}

			&__close {
				color: inherit;
				background-color: transparent;
			}
		}
	}

	&--theme-medium {
		#{$modal} {
			&__layer {
				background-color: $color_light_grey;
			}

			&__close {
				color: white;
				background-color: transparent;

				&:hover {
					background-color: $color_light_grey;
				}

				@media screen and (max-width: $breakpoint_tablet_to) {
					background-color: $color_light_grey;
				}
			}
		}
	}

	&__layer {
		position: relative;
		padding: 1rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			border-radius: $size_border_radius;
		}

		box-shadow: 0 1px 8px 0 rgba(86, 86, 86, 0.2);
		overflow-y: auto;
		max-height: 100%;
		transition: transform $animationSpeed ease, opacity $animationSpeed ease;
		animation-name: fadeIn;
		animation-direction: alternate;
		animation-timing-function: ease;

		&--fullscreen {
			max-height: 100%;
			max-width: 100%;
			width: 100vw;
		}

		&--small,
		&--medium,
		&--big {
			width: 100vw;
			height: 100vh;
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {

			max-height: calc(100vh - 7rem);
			max-width: calc(1280px - 10%);
			margin: auto;
			padding: 2rem;

			&--small {
				width: 512px;
				height: auto;
			}

			&--medium {
				width: 676px;
				height: auto;
			}

			&--big {
				width: 90%;
				height: auto;
			}

			&--fullsize {
				max-width: unset;
				width: 100%;
				max-height: unset;
				height: 100vh;
				padding: 0;
			}

			&--fullscreen {
				max-height: 100%;
				max-width: 100%;
				width: 100vw;
			}
		}
        @media only screen and (min-width: $breakpoint_desktop_from) {
          padding: 4rem;
        }
	}

	&__bg {
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		transition-property: opacity;
		transition-timing-function: linear;
		animation-name: fadeIn;
		animation-direction: alternate;
		animation-timing-function: linear;

		pointer-events: all;
	}

	&--will-close {
		#{$modal} {
			&__bg {
				opacity: 0;
			}

			&__layer {
				transform: scale(0.9);
				opacity: 0;
			}
		}
	}

	&__close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		cursor: pointer;
		border-radius: 2px;
		padding: 0;
		font-size: 1rem;
		transition: background-color $transition_hover;
		z-index: +1;
        @media only screen and (min-width: $breakpoint_tablet_from) {
          top: 0.5rem;
          right: 0.5rem;
        }
        @media only screen and (min-width: $breakpoint_desktop_from) {
          top: 2rem;
          right: 2rem;
        }
		.icon {
			&:after {
				content: '';
				position: absolute;
				width: 2rem;
				height: 2rem;
				top: -0.5rem;
				left: -0.5rem;
			}
		}
	}

	&__content {
		height: 100%;
		padding-top: 3rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			padding: 0;
		}
	}

	&--fullsize {
		position: sticky;

		opacity: 1;
		left: 0;
		top: 0;

		.modal__layer--fullsize {
			top: 0;
			left: 100%;
			padding: 0;
			max-height: unset;
			opacity: 1;
			-webkit-overflow-scrolling: touch;

			transition-property: left;
			transition-timing-function: ease-in-out;
			animation-name: slideIn;
			animation-duration: $animationSpeed;
			animation-direction: normal;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;

			box-shadow: none;
			position: fixed;

			.modal__close {
				border-radius: 0;
				height: $header_mobile_height;

				@media only screen and (min-width: 768px) {
					height: $header_height;
				}

				background-color: $color_black;
				color: $color_white;
				width: 100%;
				top: 0;
				left: 0;
				z-index: 1;
				display: flex;
				align-items: center;
				padding: 1rem;
				text-transform: uppercase;
				font-weight: bold;
				opacity: 1;

				&:hover {
					background-color: $color_gold-dark;
				}

				.icon {
					font-size: 1.5rem;
					margin-right: 0.5rem;
					top: -2px;

					&:before {
						transform: rotate(90deg);
					}
				}
			}

			.modal__content {
				width: 100vw;
				height: 100vh;
				padding: 0;
				overflow: scroll;
				opacity: 1;

				background-color: $color_beige;

				.content {
					opacity: 1;
					max-height: unset;
					min-height: 100%;
					height: auto;
					width: 100%;
					padding-right: 0;
					background-color: $color_beige;
					overflow: scroll;
				}
			}
		}

		&.modal--will-close {
			top: 0;
			left: 0;

			.modal__layer--fullsize {
				animation-name: slideOut;
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}

// fallback for IE11 and above
_:-ms-fullscreen #{$modal}, :root #{$modal} {
	position: fixed;
}
