@import "../../../vendor/css/variables.scss";
@import "../../../vendor/css/z-map.scss";

$size-arrow: 1rem;

.tooltip-wrapper {
	display: block;
	position: relative;
	pointer-events: auto;

	&__children {
		display: block;
	}

	&:hover,
	&:focus,
	&:active {
		>.tooltip {
			opacity: 1;
			z-index: $zindex_tooltip;
		}
	}
}

.tooltip {
	pointer-events: none;
	opacity: 0;
	transition: opacity ease-out 0.2s;
	position: absolute;
	display: flex;

	// Fix for ie11 offset
	flex-direction: row;
	z-index: $zindex_tooltip;

	justify-content: center;

	width: 100%;
	height: 100%;

	left: 0;
	top: 0;

	.tooltip-content {
		&:after {
			left: 50%;
		}
	}

	&.ie-fix .tooltip-content {
		left: -100%;
		box-sizing: border-box;
	}

	&.overflow-helper {
		position: fixed;
	}

	&.show-default {
		opacity: 1;
	}

	&.hide-tooltip {
		opacity: 0;
	}

	&.inactive {
		display: none;
	}

	.tooltip-content {
		display: block;
		position: absolute;

		color: $color_white;
		border: none;
		padding: 1rem;

		font-size: .875rem;
		line-height: 1.43;
		font-weight: 400;
		text-align: center;
		bottom: calc(100% + 1rem);

		min-width: 70px;
		max-width: 210px;
		width: 210px;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			max-width: 290px;
			width: 290px;
		}

		box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.1);

		flex-shrink: 0;

		// Needed for touch 'hover' (pressing and holding the element)
		user-select: none;

		&:after {
			content: "";
			display: block;
			position: absolute;
			width: $size-arrow;
			height: $size-arrow;
			z-index: 1;

			bottom: 0;
			left: 50%;
			border-top-right-radius: $size_border_radius;
			transform: translate(-50%, 50%) rotate(135deg);
		}
	}

	/* SIZES */
	&--large {
		.tooltip-content {
			width: 80vw;
			max-width: 80vw;
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {
			.tooltip-content {
				width: 500px;
				max-width: 500px;
			}
		}
	}

	/* COLORS */
	&.important {
		.tooltip-content {
			background-color: $color_orange;

			&:after {
				background-color: $color_orange;
			}
		}

	}

	&.primary {
		.tooltip-content {
			background-color: $color_orange;

			&:after {
				background-color: $color_orange;
			}
		}
	}

	&.secondary {
		.tooltip-content {
			background-color: $color_black;

			&:after {
				background-color: $color_black;
			}
		}
	}

	&.signal {
		.tooltip-content {
			background-color: $color_orange;

			&:after {
				background-color: $color_orange;
			}
		}
	}




	/* POSITIONS */

	&.top-left {
		left: 0;
		top: 0;

		.tooltip-content {
			bottom: calc(100% + 1rem);
			right: calc(50% - 3rem);

			&:after {
				right: 2rem;
				left: unset;
			}
		}

		&.ie-fix .tooltip-content {
			left: -100%;
			box-sizing: border-box;
		}
	}

	&.top-right {
		left: 0;
		top: 0;

		.tooltip-content {
			bottom: calc(100% + 1rem);
			left: calc(50% - 3rem);

			&:after {
				left: 3rem;
			}
		}

		&.ie-fix .tooltip-content {
			left: -100%;
			box-sizing: border-box;
		}
	}

	&.bottom-left {
		left: 0%;
		bottom: 0;

		.tooltip-content {
			top: calc(100% + 1rem);
			right: calc(50% - 3rem);
			bottom: unset;

			&:after {
				top: -1rem;
				right: 2rem;
				left: unset;
				transform: translate(-50%, 50%) rotate(315deg);
			}
		}

		&.ie-fix .tooltip-content {
			left: -100%;
			box-sizing: border-box;
		}
	}

	&.bottom-right {
		left: 0%;
		bottom: 0;

		.tooltip-content {
			top: calc(100% + 1rem);
			left: calc(50% - 3rem);
			bottom: unset;

			&:after {
				top: -1rem;
				left: 3rem;
				transform: translate(-50%, 50%) rotate(315deg);
			}
		}

		&.ie-fix .tooltip-content {
			left: -100%;
			box-sizing: border-box;
		}
	}

	&.bottom {
		left: 0;
		bottom: 0;

		.tooltip-content {
			top: calc(100% + 1rem);
			bottom: unset;

			&:after {
				left: 50%;
				top: -1rem;
				transform: translate(-50%, 50%) rotate(315deg);
			}
		}

		&.ie-fix .tooltip-content {
			left: -100%;
			box-sizing: border-box;
		}
	}

	&.left {
		justify-content: flex-end;
		align-items: center;
		top: 0;
		width: 100%;

		.tooltip-content {
			transform: translateX(-100%);
			left: -1rem;
			bottom: unset;
			top: unset;

			&:after {
				top: 50%;
				right: 0;
				left: unset;
				transform: translate(50%, -50%) rotate(45deg);
			}
		}
	}

	&.right {
		justify-content: flex-start;
		align-items: center;
		top: 0;
		width: 100%;

		.tooltip-content {
			transform: translateX(100%);
			right: -1rem;
			bottom: unset;
			top: unset;

			&:after {
				top: 50%;
				left: -1rem;
				transform: translate(50%, -50%) rotate(225deg);
			}
		}
	}
}
