@import "../../../vendor/css/variables.scss";

.prices {
	padding: $componentPaddingMobile 0;

	&__button {
		width: 12.75rem;
		@media screen and (min-width: $breakpoint_tablet_from) {
			width: 19.25rem;
		}
	}

	&__image {
		margin-bottom: 4rem;
		background-color: $color_orange;
		border-radius: 0.5rem;
	}

	&__headline {
		text-transform: uppercase;
		margin-bottom: 4rem;

		&--colored {
			color: $color_orange;
		}
	}

	&__subheadline {
		text-transform: uppercase;
		margin-bottom: 1rem;
	}

	&__introduction {
		padding-bottom: 4rem;
	}

	&__head {
		max-width: $text_max_width;
	}

	&__label {
		margin-bottom: 0.25rem;

		> u {
			text-decoration: none;
			padding-bottom: 4px;
			border-bottom: 3px solid $color_beige;
		}
	}

	&__item {
		margin-bottom: 1.5rem;

		&:last-child {
			margin-bottom: 4rem;
		}
	}

	&__goodies-headline {
		margin-bottom: 1.5rem;
		text-transform: uppercase;
	}
}

@media screen and (min-width: $breakpoint_desktop_from) {
	.prices {
		padding: $componentPadding 0;

		&__subheadline {
			margin-bottom: 2.5rem;
		}

		&__item {
			margin-bottom: 2.5rem;
		}

		&__goodies-headline {
			margin-bottom: 2.5rem;
		}
	}
}
