@import "../../../vendor/css/variables.scss";

.community {
	padding: $componentPaddingMobile 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}

	&__content-wrapper {
		max-width: 100%;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			max-width: $text_max_width;
		}
	}

	&__teamup-animation {
		display: inline;
		font-size: 44px;
		margin-left: -.75rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			font-size: 72px;
			margin-left: -1.25rem;
		}

		div {
			display: inline;
		}

		.humanized-text__overlayed {

			margin-right: 2rem;
			display: inherit;
			width: 120%;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				margin-right: 3.5rem;
				display: inline-block;
				max-width: 320px;
				width: 100%;
				//top: 4px;
				top: 45px;
				transform: translateY(0%);
			}
		}

		.humanized-text__animation {
			width: 120%;
			top: -14px;
			margin-right: 1.2rem;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&__button {
		margin-bottom: 4rem;
		width: 13.75rem;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			width: 19.25rem;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 4rem;
	}

	&__headline {
		text-transform: uppercase;
		margin-bottom: 2rem;
	}

	&__introduction {
		margin-bottom: 2rem;
	}

	&__item-info {
		font-size: 1.75rem;
	}

	&__item-name {
		text-transform: uppercase;
		margin-bottom: 1rem;
	}

	&__statement {
		font-size: 2rem;
	}

	&__image {
		margin-bottom: 1rem;
		border-radius: 1rem;
	}
}

@media screen and (min-width: $breakpoint_tablet_from) {
	.community {
		&__grid {
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 1.5rem;
		}

		&__headline {
			margin-bottom: 4rem;
		}

		&__introduction {
			margin-bottom: 4rem;
		}
	}
}
