@import "vendor/css/common-styles.scss";

body {
	background-color: greenyellow;

	#root {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
	}

	.cookiefirst-root {
		.cf26vr {
			display: none;
		}
	}

}
