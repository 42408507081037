@import "./src/vendor/css/variables.scss";

.faq-teaser {
	background-image: url("../../../assets/static/icons/brain.png");
	height: 400px;
	background-size: 200px 200px;


	@media only screen and (min-width: $breakpoint_mobile_from) {
		background-size: 300px 300px;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		height: 650px;
		background-size: 400px 400px;
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
		background-size: 500px 500px;
	}

	display: flex;
	justify-content: center;
	align-items: center;

	&__content-wrapper {
		background-color: $color_black;
		padding: 1rem;
		width: auto;
		> a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&__headline {
		@include h2;
		@include meister;
		text-transform: uppercase;
		margin-bottom: 2rem;
	}

}
