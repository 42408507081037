@import "src/vendor/css/variables.scss";

.stage {
	position: relative;
	width: 100%;
	background-color: $color_black;
	color: $color_beige;
	padding-bottom: $componentPaddingMobile;

	padding-top: $componentPaddingMobile;

	@media screen and (min-width: $breakpoint_tablet_from) {
		padding-bottom: $componentPadding;
		padding-top: 0;
	}

	.humanized-text {
		padding-top: $componentPaddingMobile;
		font-size: 12vw;
		margin-left: -0.25rem;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			font-size: 13vw;
			padding-top: $componentPaddingMobile;
			margin-left: -0.5rem;
		}
		@media only screen and (min-width: $breakpoint_widescreen_from) {
			font-size: 180px;
			margin-left: -1rem;
		}
	}

	&__lead {
		text-transform: uppercase;
		margin-bottom: 1rem;
	}

	&__copy {
		margin-top: 4rem;

		p + p {
			margin-top: 3.5rem;
		}
		&--bold {
			font-weight: 700;
		}
	}

	&__column-wrapper {
		display: flex;
		flex-flow: row nowrap;
	}

	&__column {
		flex-flow: column nowrap;

		&--left {
			width: 100%;
		}

		&--right {
			width: 0;

			.eye-catcher {
				position: absolute;
				top: $header_mobile_height;
				left: 0;
				@media only screen and (min-width: $breakpoint_tablet_from) {
					top: 0;
				}
			}
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {

			&--left {
				width: $text_max_width;
			}

			&--right {
				width: auto;
				display: flex;

				.eye-catcher {
					position: relative;
				}
			}
		}
	}

	&__button-container {
		display: flex;
		flex-direction: column;
		@media only screen and (min-width: $breakpoint_mobile_from) {
			flex-direction: row;
		}
	}
	&__subscription-button{
		&:first-child{
			margin-right: 1rem;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				margin-right: 2rem;
			}
		}
	}
	&__share-button {
		margin-top: 1rem;
		@media only screen and (min-width: $breakpoint_mobile_from) {
			margin-top: 0;
		}
	}
}
