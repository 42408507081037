@import "../../../vendor/css/variables.scss";

.timeline {
	.container :last-child {
		.timeline__column-left hr {
			visibility: hidden;
		}

		.timeline__column-right {
			margin-bottom: 0;
		}
	}

	margin: 0 0;

	padding: $componentPaddingMobile 0;
	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: $componentPadding 0;
	}


	background: $color_black;
	color: $color_beige;
	border-color: $color_beige;

	transition: color 0.5s, background-color 0.5s, border 3.5s;

	&__head {
		max-width: 345px;
	}

	&__register-btn {
		margin-top: 1rem;
		width: 13.73rem;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			width: 19.25rem;
		}
	}

	&__headline {
		text-transform: uppercase;

		&--colored {
			color: $color_orange;
		}
	}

	&__horizontal-line {
		border: 3px solid $color_beige;
		margin: 1rem 0;
		width: 13.73rem;
	}

	&__info {
		margin: 2rem 0 4rem;
	}

	&__vertical-line {
		height: 100%;
		width: 0;
		border: 1px solid $color_beige;
		margin: 0 auto;
	}

	&__column {
		&-left {
			display: flex;
			min-width: 50px;
			width: 50px;
			flex-direction: column;
		}

		&-right {
			white-space: pre-wrap;
			width: auto;
			margin-bottom: 4rem;
			h2,.h2{
				font-size: 2.0rem;
				@media screen and (min-width: $breakpoint_mobile_from) {
					font-size: 2.5rem;
				}
				@media screen and (min-width: $breakpoint_tablet_from) {
					font-size: 4.5rem;
				}
			}
		}
	}

	&__radio {
		width: 2rem;
		height: 2rem;
		margin: 0.5rem auto 0;
	}

	&__item {
		display: flex;
		flex-direction: row;
		width: 100%;

		&-label{
			text-transform: uppercase;
			display: flex;
			flex-flow: column nowrap;

			@media screen and (min-width: 450px) {
				flex-flow: row nowrap;
				.humanized-text__animation--circle {
					z-index: -1;
					left: -2%;
					width: 109%;
					top: -38%;
				}
			}
			@media screen and (min-width: $breakpoint_tablet_from) {
				.humanized-text__animation--circle {
					z-index: -1;
					left: -2%;
					width: 109%;
					top: -38%;
				}
			}

			> .h2{
				position: relative;
				z-index: 3;
			}
			.humanized-text{
				display: inline-flex;
			}
		}

		&-text {
			flex-grow: 0;
			max-width: 345px;
			@media screen and (min-width: $breakpoint_tablet_from) {
				max-width: 650px;
			}


			&.subline{
				margin-top: 1rem;
				em{
					font-style: normal;
					font-size: 0.75rem;
				}
			}
		}
	}

	&--theme-light {
		background: $color_beige;
		color: $color_black;
		border-color: $color_black;

		.timeline__horizontal-line {
			border: 3px solid $color_black;
		}

		.timeline__vertical-line {
			border: 1px solid $color_black;
		}

	}

}

@media screen and (min-width: $breakpoint_tablet_from) {
	.timeline {
		&__head {
			max-width: $text_max_width;
		}
	}
}

@media screen and (min-width: $breakpoint_desktop_from) {
	.timeline {
		margin: 0;
		padding: $componentPadding 0;

		&__info {
			margin-top: 4rem;
		}

		&__radio {
			width: 4rem;
			height: 4rem;
			margin: 1rem auto 0;
		}

		&__column {
			&-left {
				width: 6.625rem;
			}

			&-right {
				width: auto;
				margin-bottom: 5.875rem;
			}
		}
	}
}
